<app-svg-definitions></app-svg-definitions>
<app-loader *ngIf="status === states.PENDING  || !translationLoaded"></app-loader>

<app-header
  [constructionSiteAddress]="constructionSiteAddress"
  [materialCategoryName]="materialCategoryName"
  [supplierName]="supplierName"
  [supplierPhone]="supplierPhone">
</app-header>

<ng-container *ngIf="translationLoaded">

  <app-main (formSubmit)="save($event)"
            *ngIf="deliveryStage && status !== states.SUCCESS && stageStatus === 'WAITING_FOR_ACTION'"
            [deliveryStage]="deliveryStage"
            [stageStatus]="stageStatus"
            [tokenDecoded]="tokenDecoded"></app-main>

  <app-success *ngIf="status === states.SUCCESS"
               [supplierName]="supplierName"></app-success>

  <app-accepted *ngIf="status !== states.ERROR && status !== states.SUCCESS && stageStatus !== 'WAITING_FOR_ACTION'"></app-accepted>

  <app-error *ngIf="status === states.ERROR"></app-error>

</ng-container>

<footer>
  <span class="bold">{{ now | date : 'd/MM/yyyy H:mm' }}</span> © ProperGate
</footer>

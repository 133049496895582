import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
})
export class SvgIconComponent {
  @Input() name: string;
  @Input() size = '16';
  @Input() height: number;
  @Input() width: number;
  @Input() display = 'block';
  @Input() color = 'white';

  get iconUrl() {
    return `${this.location.path()}#${this.name}`;
  }

  constructor(private location: Location) { }
}

<div class="rescheduled-content">
  <div class="rescheduled-content__info">
    {{ 'sub_app.rescheduled_stage_info' | translate }}
  </div>
  <div class="edit-buttons">
    <button (click)="redirectToApp()" class="login">
      {{ 'sub_app.rescheduled_stage_login' | translate }}
    </button>
  </div>
</div>

<div>
  <h1> {{ 'sub_app.thanks' | translate }} </h1>
  <p> {{ 'sub_app.eo_confirmation' | translate }} </p>
  <p> {{ 'sub_app.close_browser' | translate }} </p>
  <img alt="logo"
       src="../../assets/{{supplierName}}-logo.png">
  <div class="small"> {{ 'sub_app.concrete_system' | translate }}</div>
  <div class="small"> powered by </div>
  <img alt="logo"
       src="../assets/ppg-logo.png">
  <div class="small">ProperGate</div>
</div>

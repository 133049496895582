export enum APP_STATE {
  PENDING = 'pending',
  STANDBY = 'standby',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum SAVE_ACTION {
  CONFIRM = 'confirm',
  CANCEL = 'cancel',
  CHANGE = 'change',
}

export enum RESCHEDULE_ACTION {
  EARLIER = 'EARLIER',
  LATER = 'LATER',
}

export enum DISPATCHED_ACTION {
  CONFIRM = 'CONFIRM',
  REJECT = 'REJECT',
}


import { Component, Input } from '@angular/core';
import { DeliveryStage } from '../interface';
import { redirectToAppUrl } from '../helpers/redirect.helper';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  @Input() deliveryStage?: DeliveryStage;

  public redirectToApp() {
    window.open(redirectToAppUrl(this.deliveryStage));
  }
}

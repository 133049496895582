import { Component, OnInit } from "@angular/core";
import { filter, map, switchMap, take } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin } from "rxjs";
import jwt_decode from "jwt-decode";

import { environment } from "../environments/environment";
import {
  APP_STATE,
  DISPATCHED_ACTION,
  RESCHEDULE_ACTION,
  SAVE_ACTION,
} from "./@consts";
import {
  Contract,
  DeliveryActionsParams,
  GeneralSettings,
  TokenDecoded,
  UserMe,
} from "./@consts/interfaces";
import { DeliveryStage, ITranslations, Res } from "./interface";
import { redirectToAppUrl } from "./helpers/redirect.helper";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  public token: string;
  public tokenDecoded: TokenDecoded;
  public now = new Date();
  public status: APP_STATE;
  public states = APP_STATE;
  public deliveryId: string;
  public stageId: string;
  public deliveryStage: DeliveryStage;
  public translations: { [key: string]: { [key: string]: string } } = null;
  public defaultLang = "en";
  public appLang: string;
  public translationLoaded: boolean;
  public stageStatus: string;
  public materialCategoryName: string;
  public supplierName: string;
  public supplierPhone: string;
  public constructionSiteAddress: string;

  private get options() {
    return {
      headers: {
        Authorization: this.token,
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };
  }

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.status = APP_STATE.ERROR;
    this.getTranslations();

    this.route.queryParams
      .pipe(
        filter(({ token }) => !!token),
        switchMap(({ token, deliveryId, deliveryStageId }) => {
          this.status = APP_STATE.PENDING;
          this.token = token;
          this.deliveryId = deliveryId;
          this.stageId = deliveryStageId;
          this.isTokenValid(this.token);

          return forkJoin(
            this.http.get<DeliveryStage>(
              `${environment.apiUrl}/deliveries/${this.deliveryId}/stages/${this.stageId}`,
              this.options
            ),
            this.http.get<UserMe>(
              `${environment.apiUrl}/users/get/me`,
              this.options
            )
          );
        })
      )
      .subscribe(
        ([deliveryStage, userMe]) => {
          this.deliveryStage = deliveryStage;
          const { language } = userMe.data.user;
          this.appLang = language;
          this.stageStatus = this.deliveryStage.status;
          this.status = APP_STATE.STANDBY;

          this.getContract(deliveryStage.delivery.contractId);
          this.getSettings();
          if (this.appLang === this.defaultLang) {
            return;
          }

          this.setAppLanguage();
        },
        () => {
          this.status = APP_STATE.ERROR;
        }
      );
  }

  private getContract(id: string): void {
    this.http
      .get(`${environment.apiUrl}/contracts/${id}`, this.options)
      .pipe(
        map(
          (response: { data: { contract: Contract } }) => response.data.contract
        ),
        take(1)
      )
      .subscribe((contract) => {
        this.supplierName = contract.supplier.name;
        this.supplierPhone =
          contract.supplier &&
          contract.supplier.supplierUser &&
          contract.supplier.supplierUser.user &&
          contract.supplier.supplierUser.user.phone;
        const materialCategoryTranslation =
          contract.materialCategory.translation;
        this.materialCategoryName =
          materialCategoryTranslation.data[this.appLang] ||
          contract.materialCategory.name;
      });
  }

  private getSettings(): void {
    this.http
      .get(`${environment.apiUrl}/settings`, this.options)
      .pipe(take(1))
      .subscribe((response: { generalSettings: GeneralSettings }) => {
        this.constructionSiteAddress =
          response.generalSettings.constructionSiteAddress;
      });
  }

  public save({ action, time }) {
    if (!action) {
      return;
    }

    this.status = APP_STATE.PENDING;
    let saveParams: DeliveryActionsParams;

    switch (action) {
      case SAVE_ACTION.CONFIRM:
        saveParams = {
          dispatchedAction: DISPATCHED_ACTION.CONFIRM,
        };
        break;
      case SAVE_ACTION.CANCEL:
        saveParams = {
          dispatchedAction: DISPATCHED_ACTION.REJECT,
        };
        break;
      case SAVE_ACTION.CHANGE:
        if (!time) {
          return;
        }
        saveParams = {
          dispatchedAction: DISPATCHED_ACTION.CONFIRM,
          rescheduleAction:
            time > 0 ? RESCHEDULE_ACTION.LATER : RESCHEDULE_ACTION.EARLIER,
          rescheduleValue: Math.abs(time),
        };
        break;
    }

    this.http
      .post<Res>(
        `${environment.apiUrl}/deliveries/${this.deliveryId}/stages/${this.stageId}/action`,
        saveParams,
        this.options
      )
      .subscribe(
        () => {
          this.status = APP_STATE.SUCCESS;
        },
        () => {
          this.status = APP_STATE.STANDBY;
        }
      );
  }

  public getTranslations() {
    return this.http
      .get<{ data: ITranslations }>(`${environment.apiUrl}/translations`)
      .pipe(map((res) => res.data))
      .subscribe((translations) => {
        this.translations = translations;
        Object.keys(translations).forEach((langCode) => {
          this.translateService.setTranslation(
            langCode,
            translations[langCode]
          );
        });
        this.translationLoaded = true;
        this.setAppLanguage();
      });
  }

  public isTokenValid(token) {
    this.tokenDecoded = jwt_decode(token);

    if (this.tokenDecoded.exp * 1000 <= Date.now()) {
      window.open(redirectToAppUrl(this.deliveryStage));
      return;
    }
  }

  private setAppLanguage() {
    this.translateService.setDefaultLang(this.appLang || this.defaultLang);
  }
}

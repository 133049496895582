import { environment } from '../../environments/environment';
import { DeliveryStage } from '../interface';

export const redirectToAppUrl = (deliveryStage: DeliveryStage) => {
  if (!deliveryStage) {
    return environment.appUrl;
  }

  const urlParams = `?singleDeliveryId=${deliveryStage.delivery.id}&deliveryStageId=${deliveryStage.id}&redirectAfterLogin=true`;
  return `${environment.appUrl}/deliveries/today/longterm${urlParams}`;
};

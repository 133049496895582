<div *ngIf="deliveryStage">
  <div class="content">
    <div class="row">
      <div class="bold">D {{deliveryStage.delivery.number}} - {{deliveryStage.code}}</div>
      <div class="info">{{'sub_app.accepted_by_sub' | translate}}</div>
    </div>
    <ng-container *ngIf="!rescheduledStage; else stageRescheduled">
    <div class="row">
      <div>{{deliveryStage.material.name}}</div>
      <div>{{deliveryStage.date | date: 'dd/MM'}}</div>
    </div>
    <div class="row">
      <div>{{deliveryStage.amountSize}} {{deliveryStage.unit}}</div>
      <div>
        <ng-container *ngIf="form.value.action === 'change'; else justDeliveryTime">
          <span>{{ changedTime }}</span>
          <span [class.line-through]="changedTime">{{ deliveryTime }}</span>
        </ng-container>
        <ng-template #justDeliveryTime>
          <span>{{ deliveryTime }}</span>
        </ng-template>
      </div>
    </div>
    <div class="row purpose">
      <div>{{'sub_app.destination' | translate}}</div>
      <div>{{deliveryStage.materialDestination.name}}</div>
    </div>
    <div class="row purpose">
      <div>{{'sub_app.unloading' | translate}}</div>
      <div>{{deliveryStage.unloadingMethod.name}}</div>
    </div>

    <form [formGroup]="form">
      <div class="action-buttons">
        <label class="radio">
          <input formControlName="action"
                 type="radio"
                 value="confirm">
          <div [class.selected]="form.value.action === 'confirm'">
            <app-svg-icon [color]="form.value.action === 'confirm' ? blue : 'white'"
                          name="confirm"
                          size="25"></app-svg-icon>
          </div>
          <span>{{'sub_app.accept' | translate}}</span>
        </label>

        <label class="radio">
          <input formControlName="action"
                 type="radio"
                 value="cancel">
          <div [class.selected]="form.value.action === 'cancel'">
            <app-svg-icon [color]="form.value.action === 'cancel' ? blue : 'white'"
                          name="cancel"
                          size="20"></app-svg-icon>
          </div>
          <span>{{'sub_app.reject' | translate}}</span>
        </label>

        <label class="radio">
          <input formControlName="action"
                 type="radio"
                 value="change">
          <div [class.selected]="form.value.action === 'change'">
            <app-svg-icon [color]="form.value.action === 'change' ? blue : 'white'"
                          name="clock"
                          size="30"></app-svg-icon>
          </div>
          <span>{{'sub_app.reschedule_and_accept' | translate}}</span>
        </label>
      </div>

      <ng-container *ngIf="form.value.action === 'change'">
        <div class="time-option-buttons">

          <label [class.selected]="form.value.option === 'earlier'"
                 class="radio">
            <input formControlName="option"
                   type="radio"
                   value="earlier">
            {{'sub_app.earlier' | translate}}
          </label>

          <label [class.selected]="form.value.option === 'later'"
                 class="radio">
            <input formControlName="option"
                   type="radio"
                   value="later">
            {{'sub_app.later' | translate}}
          </label>
        </div>

        <div class="time-range-buttons">
          <label *ngFor="let time of times"
                 [class.selected]="form.value.time === time"
                 class="radio">
            <input [value]="time"
                   formControlName="time"
                   type="radio">
            {{ time }} <span>min</span>
          </label>
        </div>

      </ng-container>

    </form>
    </ng-container>
  </div>

  <div *ngIf="!rescheduledStage" class="edit-buttons">
    <button (click)="redirectToApp()"
            class="edit">
      <app-svg-icon height="25"
                    name="edit"
                    width="30"></app-svg-icon>
      <div>
        <div class="label">{{'sub_app.edit' | translate}}</div>
        <span>{{'sub_app.after_login' | translate}}</span>
      </div>
    </button>
    <button (click)="submit()"
            class="save">
      {{'sub_app.save' | translate}}
    </button>
  </div>

  <ng-template #stageRescheduled>
    <app-error [deliveryStage]="deliveryStage"></app-error>
  </ng-template>


</div>

<header>
  <div class="info">
    <div *ngIf="constructionSiteAddress">
      <app-svg-icon display="inline"
                    name="building"
                    width="35"></app-svg-icon>
      <span>{{ constructionSiteAddress }}</span>
    </div>
    <div *ngIf="supplierName">
      <app-svg-icon display="inline"
                    name="product"
                    width="35"></app-svg-icon>
      <span>{{ materialCategoryName }} {{'general.from' | translate}} {{ supplierName }}</span>
    </div>
  </div>
  <a [href]="'tel:' + supplierPhone">
    <app-svg-icon name="phone"
                  size="40"></app-svg-icon>
  </a>
</header>

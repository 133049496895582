import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() public materialCategoryName: string;
  @Input() public supplierName: string;
  @Input() public supplierPhone: string;
  @Input() public constructionSiteAddress: string;
}
